exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-a-propos-jsx": () => import("./../../../src/pages/a-propos.jsx" /* webpackChunkName: "component---src-pages-a-propos-jsx" */),
  "component---src-pages-cgv-jsx": () => import("./../../../src/pages/cgv.jsx" /* webpackChunkName: "component---src-pages-cgv-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-mentions-legales-jsx": () => import("./../../../src/pages/mentions-legales.jsx" /* webpackChunkName: "component---src-pages-mentions-legales-jsx" */),
  "component---src-pages-services-jsx": () => import("./../../../src/pages/services.jsx" /* webpackChunkName: "component---src-pages-services-jsx" */),
  "component---src-pages-statuts-jsx": () => import("./../../../src/pages/statuts.jsx" /* webpackChunkName: "component---src-pages-statuts-jsx" */)
}

